import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {push} from 'react-router-redux';
import {store} from '@mini-core/core';
import * as API from '../../networking';

import {
  Button,
  FixedLayout,
  Panel,
  PanelHeader,
  PanelSpinner,
  Separator,
  Alert
} from '@vkontakte/vkui';
import SmartSlider from '../../components/SmartSlider';
import MyConferences from '../../components/MyConferences';
import InvitationCard from '../../components/InvitationCard';
import PlanningSnack from '../../components/PlanningSnack';
import PlanningDesc from '../PlanningDesk';

import './index.scss';
import camera from '../../assets/camera.png';
import camera2x from '../../assets/camera@2x.png';
import camera3x from '../../assets/camera@3x.png';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import {setHistoryPush, setPopout} from "../../store/globals/actions";


const Conferences = ({id}) => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conferencesState, conferencesActions] = store.useModel('conferences');
  const {conferences, invites, isPlanned, isSubstrate} = conferencesState;

  useEffect(() => {
    if (isPlanned) {
      setSnackbar(<PlanningSnack onClose={setSnackbar}/>);
      conferencesActions.toPlan(false);
    }
  }, [isPlanned]);

  useEffect(() => {
    API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
    API.getUserInvites().then(data => data && conferencesActions.loadInvites(data.meetings));
  }, []);

  const createConference = () => {
    setLoading(true);
    API.createMeeting({type: 1})
      .then((newConference) => {
        conferencesActions.add(newConference.meeting);
        conferencesActions.setNewConference(newConference.meeting);
        dispatch(setHistoryPush('done'));
        dispatch(push('done'));
      })
      .catch(() => {
        dispatch(setPopout(
          <Alert
            actions={[{
              title: 'Закрыть',
              autoclose: true,
              mode: 'cancel'
            }]}
            onClose={() => dispatch(setPopout(null))}
          >
            <h2>Упс...</h2>
            <p>Что-то пошло не так</p>
          </Alert>
        ));
      })
      .finally(() => setLoading(false));
  };

  const logOutUser = () => {
    dispatch(setPopout(
      <Alert
        actions={[
          {
            title: 'Отмена',
            autoclose: true,
            mode: 'cancel'
          },
          {
            title: 'Да',
            autoclose: true,
            action: () => API.logoutUser()
              .then(data => {
                if (data.success) {
                  dispatch(setHistoryPush('welcome'));
                  dispatch(push('welcome'));
                }
              })
              .catch(() => {
                dispatch(setPopout(
                  <Alert
                    actions={[{
                      title: 'Закрыть',
                      autoclose: true,
                      mode: 'cancel'
                    }]}
                    onClose={() => dispatch(setPopout(null))}
                  >
                    <h2>Упс...</h2>
                    <p>Что-то пошло не так</p>
                  </Alert>
                ))
              })
          }
        ]}
        onClose={() => dispatch(setPopout(null))}
      >
        <h2>Подтвердите действие</h2>
        <p>Вы действительно хотите выйти?</p>
      </Alert>
    ))
  };

  const scheduleConference = () => {
    dispatch(setHistoryPush('planning'));
    dispatch(push('planning'));
  };

  return (
    <Panel
      id={id}
    >
      <PanelHeader>
        {/*Конференции*/}
        Conferences
      </PanelHeader>
      {conferences && invites ?
        <div
          className="conferences"
          style={
            {
              overflow: popup && 'hidden',
              height: popup && '100vh'
            }
          }
        >
          <div
            className="conferences__header"
          >
            <div className="conferences__title">
              Conferences
              {/*Конференции*/}
            </div>
            <div className="conferences__controls">
              <Button
                mode="secondary"
                onClick={() => setPopup(true)}
              >
                Schedule
                {/*Запланировать*/}
              </Button>
              <Button
                onClick={createConference}
              >
                Create a conference
                {/*Создать конференцию*/}
              </Button>
            </div>
          </div>
          {conferences &&
          invites &&
          <div className="conferences__subheader">
            <div
              className="conferences__add-widget"
              onClick={logOutUser}
            >
              <Icon24Dismiss/>
              Logout from Zoom
              {/*Выйти из аккаунта Zoom*/}
            </div>
            <Separator/>
          </div>
          }
          {invites && invites.length !== 0 &&
          <SmartSlider>
            {invites &&
            invites
              .map((conference) => <InvitationCard key={conference.id} {...conference}/>)
            }
          </SmartSlider>
          }
          <div className="container">
            {conferences.length === 0 &&
            invites.length === 0 &&
            <div className="conferences__none">
              <img src={camera} srcSet={`${camera2x} 2x, ${camera3x} 3x`} alt=""/>
              <div>No upcoming<br/> conferences</div>
            </div>
            }
            {conferences &&
            conferences.length !== 0 &&
            <MyConferences
              conferences={conferences}
            />
            }
          </div>
          <FixedLayout vertical="bottom">
            <Separator/>
            <div className="conferences__footer">
              <Button
                stretched
                size="xl"
                onClick={createConference}
              >
                Create a conference
              </Button>
              <Button
                stretched
                size="xl"
                mode="secondary"
                onClick={scheduleConference}
              >
                Schedule
              </Button>
            </div>
            {snackbar}
          </FixedLayout>
        </div> :
        <PanelSpinner/>
      }
      {popup && <PlanningDesc setPopup={setPopup}/>}
      {loading && <ScreenSpinner/>}
      {alert}
      {isSubstrate &&
      <div className="conferences__substrate">
      </div>
      }
    </Panel>
  )
};

export default Conferences;

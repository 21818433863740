import React, { useState, useEffect } from 'react';
import { store } from '@mini-core/core';
import moment from 'moment';
import FooterSwitch from '../../components/FooterSwitch';

import './index.scss';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import PlanningForm from "../../components/PlanningForm";


const PlanningDesc = ({ setPopup }) => {
    const [conferences, conferencesActions] = store.useModel('conferences');
    const [newConference, setNewConference] = useState(
        {
            topic: '',
            type: 2,
            start_time: '',
            exact_time: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            duration: 0,
            currentOption: '',
            members: [],
            stream: null,
        }
    );

    const setConferenceField = (value, field, optionField = 'currentOption') => {

        if (field === 'start_time' && optionField === 'edit') {
            setNewConference((prev) => ({
                ...prev,
                [field]: value
            }));
            return;
        }

        if (field === 'start_time') {
            setNewConference((prev) => ({
                ...prev,
                [field]: value !== '' ? moment().add(value - 1, 'day').format("YYYY-MM-DD[T]HH:mm:ss") : '',
                [optionField]: value
            }));
            return;
        }

        setNewConference((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    useEffect(() => {
        conferencesActions.setNewConference(null);
        conferencesActions.setStreamSwitchState(false);
    }, []);

    return (
        <div className="planning-desktop">
            <div className="planning-desktop__wrapper">

                <div className="planning-desktop__header">
                    <div className="planning-desktop__title">
                        {/*Создание*/}
                        Create
                    </div>
                    <div className="stream-alert__close">
                        <Icon16Cancel
                            fill="#818c99"
                            onClick={() => setPopup(false)}
                        />
                    </div>
                </div>
                <PlanningForm
                  startTime={newConference.start_time}
                  exactTime={newConference.exact_time}
                  duration={newConference.duration}
                  inputTopicValue={newConference.topic}
                  stateSetter={setConferenceField}
                />
            </div>
                <FooterSwitch
                    setNewConference={setConferenceField}
                    newConference={newConference}
                    setPopup={setPopup}
                />
                <div
                    className="planning-desktop__mask"
                    onClick={() => setPopup(false)}
                >
                </div>
            </div>
    )
};

export default PlanningDesc;

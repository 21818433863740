import React, {useEffect, useState} from 'react';
import { push } from 'react-router-redux';
import {useDispatch} from 'react-redux';
import { store } from '@mini-core/core';
import {Panel, ScreenSpinner} from '@vkontakte/vkui';
import * as API from '../../networking';
import {setHistoryPush} from "../../store/globals/actions";

const Empty = ({id}) => {
  const dispatch = useDispatch();
  const [user, userActions] = store.useModel('user');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.getUser()
      .then((data) => {
        if (data.found) { // !data.found
          dispatch(setHistoryPush('conferences'));
          dispatch(push('conferences'));
          userActions.loadZoomProfile({...data});
        } else {
          dispatch(push('welcome'));
        }
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <Panel
      id={id}
      centered={loading}
    >
      {loading && <ScreenSpinner/>}
    </Panel>
  )
};

export default Empty;

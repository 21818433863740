import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import {setActiveModal, setHistoryPop} from "../store/globals/actions";
import * as API from '../networking';
import {store} from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';

import {ConfigProvider, ModalRoot, View} from '@vkontakte/vkui';
import Welcome from './Welcome';
import Conferences from './Conferences';
import Planning from './Planning';
import Done from './Done';
import StreamAlert from '../components/StreamAlert';
import PlanningDesc from './PlanningDesk';
import EditConference from './EditConference';
import Empty from './Empty';

import './index.scss';

const App = ({pageId}) => {
  const dispatch = useDispatch();
  const [activePanel, setActivePanel] = useState('empty');
  const activePopout = useSelector(store => store.globals.activePopout);
  const activeModal = useSelector(store => store.globals.activeModal);
  const history = useSelector(store => store.globals.history);
  const [user, userActions] = store.useModel('user');
  const [conferences, conferencesActions] = store.useModel('conferences');
  const {profile, user_token} = user;

  useEffect(() => {
    API.getProfile()
      .then(profile => {
        userActions.load(profile);
        if (window.location.hash.length !== 0) {
          const meetingId = parseInt(window.location.hash.match(/\d+/));
          if (!window.location.hash.includes(profile.id)) {
            API.sendInvites(meetingId, [profile.id]);
          }
        }
      });
    bridge.send("VKWebAppGetAuthToken", {"app_id": 7453536, "scope": "video"})
      .then(((data) => userActions.loadUserToken(data.access_token)));
  }, []);

  useEffect(() => {
    if (window.location.hash.includes('invite')) {
      setActivePanel('conferences');
      return;
    }
    setActivePanel(pageId);
  }, [pageId]);


  const onCloseModal = () => {
    dispatch(setActiveModal(null));
  };

  const stepBack = () => {
    dispatch(setHistoryPop());
    dispatch(goBack());
  };

  const modals = (
    <ModalRoot activeModal={activeModal} onClose={onCloseModal}>
      <StreamAlert id="stream-alert"/>
    </ModalRoot>
  );

  return (
    <ConfigProvider
      isWebView={true}
    >
      <View
        onSwipeBack={stepBack}
        history={history}
        activePanel={activePanel}
        modal={modals}
        popout={activePopout}
      >
        <Empty id="empty"/>
        <Welcome
          id="welcome"
          userId={profile && profile.id}
        />
        <Conferences id="conferences"/>
        <Planning
          id="planning"
          stepBack={stepBack}
        />
        <EditConference
          id="edit-conference"
          conferences={conferences.conferences.length !== 0 && conferences.conferences}
          stepBack={stepBack}
        />
        <PlanningDesc
          id="planning-desc"
          stepBack={stepBack}
        />
        <Done
          id="done"
          user_token={user_token}
          userId={profile && profile.id}
          stepBack={stepBack}
        />
      </View>
    </ConfigProvider>
  );
};

export default App;

import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {goBack} from 'react-router-redux';
import {store} from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';
import Clipboard from 'clipboard';

import {Alert, Button, Panel, PanelHeader, PanelHeaderBack} from '@vkontakte/vkui';
import FooterSwitch from '../../components/FooterSwitch';

import * as API from '../../networking';
import FireEvent from '../../utils/FireEvent';

import './index.scss';
import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';
import {setPopout} from "../../store/globals/actions";

const Done = ({id, user_token, userId}) => {
  const dispatch = useDispatch();
  const [conferencesState, conferencesActions] = store.useModel('conferences');
  const {conferences, newConference} = conferencesState;

  new Clipboard('.clipboard-btn');

  const sendInvites = () => {
    bridge.send("VKWebAppShare", {"link": newConference.type === 1 ? newConference.join_url : `https://vk.com/app7453536#invite${newConference._id}${userId}`})
      .then(data => {
        const membersId = data.users.map(user => user.id);
        API.sendInvites(newConference._id, membersId)
          .then(response => {
            if (response.success) {
              API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
              API.getUserInvites().then(data => data && conferencesActions.loadInvites(data.meetings))
            }
          });
      });
  };

  const openJoinUrl = () => {
    FireEvent(newConference.join_url);
  };

  const updateStreamStatus = (checked) => {
    if (checked) {
      bridge.send("VKWebAppCallAPIMethod",
        {
          "method": "video.startStreaming", "request_id": "32test32",
          "params": {
            "name": "Тестовая трансляция",
            "description": "Описание тестовой трансляции",
            "v": "5.107",
            "access_token": user_token
          }
        })
        .then((result) => {
          API.updateMeeting({_id: newConference._id, stream: result.response.stream});
          conferencesActions.setStreamSwitchState(checked);
        })
        .catch(() => {
          dispatch(setPopout(
            <Alert
              actions={[{
                title: 'Cancel',
                autoclose: true,
                mode: 'cancel'
              }]}
              onClose={() => dispatch(setPopout(null))}
            >
              <h2>Ops...</h2>
              <p>Something goes wrong</p>
            </Alert>
          ))
        });
      return;
    }

    bridge.send("VKWebAppCallAPIMethod",
      {
        "method": "video.stopStreaming",
        "request_id": "32test32",
        "params": {"v": "5.107", "access_token": user_token}
      })
      .then((result) => {
        API.updateMeeting({_id: newConference._id, stream: null});
        conferencesActions.setStreamSwitchState(checked);
      })
      .catch(() => {
        dispatch(setPopout(
          <Alert
            actions={[{
              title: 'Cancel',
              autoclose: true,
              mode: 'cancel'
            }]}
            onClose={() => dispatch(setPopout(null))}
          >
            <h2>Ops...</h2>
            <p>Something goes wrong</p>
          </Alert>
        ))
      });
  };

  return (
    <Panel id={id} centered>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={() => dispatch(goBack())}
          />
        }
      />
      <div className="done-panel">
        <div className="container">
          <Icon56CheckCircleOutline fill="#3f8ae0"/>
          <div className="done-panel__title">
            Conference has been {newConference.type === 1 ? 'created' : 'scheduled'}
          </div>
          <div className="done-panel__controls">
            <Button
              className="done-panel__add-btn"
              stretched
              size="xl"
              mode="primary"
              onClick={sendInvites}
            >
              Invite members
            </Button>
            {newConference.type === 1 &&
            <Button
              stretched
              size="xl"
              mode="secondary"
              onClick={openJoinUrl}
            >

              Go to the conference
            </Button>
            }
            <Button
              stretched
              size="xl"
              mode="secondary"
            >
              <span className="clipboard-btn"
                    data-clipboard-text={newConference.type === 1 ? newConference.join_url : `https://vk.com/app7453536#invite${newConference._id}${userId}`}>
                Copy link
              </span>
            </Button>
          </div>
        </div>
        <FooterSwitch
          updateStreamStatus={updateStreamStatus}
          hideBtn={true}
        />
      </div>
    </Panel>
  )
};

export default Done;

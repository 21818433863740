import React from 'react';
import {Snackbar} from '@vkontakte/vkui';

import './index.scss';
import Icon16CheckCircle from '@vkontakte/icons/dist/16/check_circle';

const PlanningSnack = ({onClose}) => {
    return (
        <Snackbar
            onClose={() => onClose(null)}
            duration={5000}
            vertical="vertical"
            before={
                <Icon16CheckCircle width={24} height={24} fill="#528bcc"/>
            }
        >
            Conferences has been created
        </Snackbar>
    )
};

export default PlanningSnack;

import React from 'react';
import moment from 'moment';

import { FormLayout, Input, Select } from '@vkontakte/vkui';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import './index.scss';

const PlanningForm = ({inputTopicValue, isEdit = false, startTime = null, exactTime = null, duration = null, stateSetter, children = null}) => {
    const days = [1, 2, 3, 4, 5, 6, 7];

    return (
        <FormLayout>
            <Input
                type="text"
                top="Topic"
                name="topic"
                value={inputTopicValue} //inputTopicValue
                onChange={(e) => {
                  if (e.target.value.length <= 50) {
                    stateSetter(e.target.value, e.target.name)
                  }
                }}
            />
            <Select
                top="Start date"
                placeholder="Choose date"
                defaultValue={isEdit && '0'}
                onChange={(e) => {
                  if (e.target.value === '0') {
                    stateSetter(startTime, 'start_time')
                  } else {
                    stateSetter(e.target.value, 'start_time')
                  }
                }}
            >
                {isEdit &&
                  <option
                    key={0}
                    value={0}
                  >
                    {moment(startTime).format("DD MMMM")}
                  </option>
                }
                {days.map((day) =>
                    <option
                        key={day}
                        value={day}
                    >
                        {moment().add(day - 1, 'day').format("DD MMMM")}
                    </option>)
                }
            </Select>
            <Input
              className="planning-form__input-time"
              top="Start time"
              type="time"
              value={exactTime}
              onChange={(e) => stateSetter(e.target.value, 'exact_time', 'start_time')}
            />
            <div className="planning-form__polyfill">
              <label>
                Start time
                <TimePicker
                  showSecond={false}
                  onChange={(value) => {
                    stateSetter(moment(value._d).format('HH:mm'), 'exact_time', 'start_time')
                  }}
                />
              </label>
            </div>
            <Select
                top="Duration"
                placeholder="Choose duration"
                value={duration}
                onChange={(e) => stateSetter(e.target.value, 'duration')}
            >
                <option value="15">15 minutes</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">1 hour</option>
                <option value="100">more than an hour</option>
            </Select>
            {children}
        </FormLayout>
    )
};

export default PlanningForm;

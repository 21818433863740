import React, { useState } from 'react';

import './index.scss';

const CustomStackItem = ({member}) => {
  const [tip, showTip] = useState(false);

  const tipHandler = (e, value) => {
    e.stopPropagation();
    showTip(value);
  };

  return (
    <div style={{position: 'relative'}}>
      <div
        className="UsersStack__photo"
        style={
          {
            backgroundImage: `url(${member.photo_100})`
          }
        }
        onMouseMove={(e) => tipHandler(e, true)}
        onMouseLeave={(e) => tipHandler(e, false)}
      >
      </div>
        <div
          className={!tip ? "UsersStack__tip" : "UsersStack__tip UsersStack__tip_show"}
        >
          {member.first_name} {member.last_name}
        </div>
      <div
        className={!tip ? "UsersStack__tip_after" : "UsersStack__tip_after UsersStack__tip_after-show"}
      >

      </div>
    </div>
  )
};

export default CustomStackItem;

import React from 'react';
import { push } from 'react-router-redux';
import { store, utils } from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';
import * as API from '../../networking/';
import moment from 'moment';
import 'moment/locale/ru';
import PropTypes from 'prop-types';
import { Card } from '@vkontakte/vkui';
import PopoutEdit from '../PopoutEdit';
import CustomStack from '../CustomStack';

import './index.scss';
import Icon20CalendarOutline from '@vkontakte/icons/dist/20/calendar_outline';
import Icon20RecentOutline from '@vkontakte/icons/dist/20/recent_outline';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import Icon28SettingsOutline from '@vkontakte/icons/dist/28/settings_outline';
import Icon24UserAddOutline from '@vkontakte/icons/dist/24/user_add_outline';
import {useDispatch} from "react-redux";
import {setHistoryPush, setPopout} from "../../store/globals/actions";



const ConferenceCard = ({ profileId, _id, type, topic, start_time, author, duration, password, members = [], join_url, mode = 'tint'}) => {
    const dispatch = useDispatch();
    const [conferencesState, conferencesActions] = store.useModel('conferences');

    const deleteHandler = () => {
        API.deleteMeeting(_id).then(status => {
            if (status) {
                conferencesActions.delete(_id);
                API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
            }
        })
          .catch(() => {
              conferencesActions.delete(_id);
              API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
          });
    };

    const editHandler = () => {
        conferencesActions.setEditedConferenceId(_id);
        dispatch(setHistoryPush('edit-conference'));
        dispatch(push('edit-conference'));
    };

    const onEditBtnClick = () => {
        dispatch(
          setPopout(<PopoutEdit
            editHandler={editHandler}
            deleteHandler={deleteHandler}
          />)
        )
    };

    const addParticipantsHandler = () => {
        bridge.send("VKWebAppShare", {"link": type === 1 ? join_url : `https://vk.com/app7453536#invite${_id}${profileId}`})
          .then(data => {
              const membersId = data.users.map(user => user.id);
              API.sendInvites(_id, membersId)
                .then(response => {
                    if (response.success) {
                        API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
                        API.getUserInvites().then(data => data && conferencesActions.loadInvites(data.meetings))
                    }
                });
          });
    };

    return (
        <Card mode={mode}>
            <div className="conference-card">
                <div className="conference-card__title">
                    {topic}
                    <div
                        style={
                            {
                                backgroundColor: type === 1 ? 'rgba(129, 140, 153, 0.12)' : null
                            }
                        }
                        className="conference-card__close"
                    >
                        {type === 1 &&
                            <Icon16Cancel
                                fill="#818c99"
                                onClick={deleteHandler}
                            />
                        }
                        {type === 2 &&
                            Number(author) === Number(profileId) &&
                            <Icon28SettingsOutline
                              fill="#818c99"
                              onClick={onEditBtnClick}
                            />
                        }
                    </div>
                </div>
                {start_time  &&
                    <div className="conference-card__date">
                        <Icon20CalendarOutline fill="#6d7885"/>
                        {moment(start_time).format("DD MMMM")}, &nbsp;
                        {moment(start_time).format("HH:mm")}
                    </div>
                }
                {duration && duration !== 0 ?
                    <div className="conference-card__duration">
                        <Icon20RecentOutline fill="#6d7885"/>
                        {duration === 100 && 'more than an hour'}
                        {duration === 60 && '1 час'}
                        {duration < 60 && `${duration} minutes`}
                    </div> : null
                }
                <div className="conference-card__wrapper">
                    <div className="conference-card__participants">
                        <CustomStack members={members}/>
                        <span style={ {display: 'none'} }>{password}</span>
                    </div>
                    <a
                        className="conference-card__btn"
                        href={join_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Join
                    </a>
                    <div
                      className="conference-card__add-participants"
                      onClick={addParticipantsHandler}
                    >
                        <Icon24UserAddOutline fill="#99a2ad"/>
                    </div>
                </div>
            </div>
        </Card>
    )
};

ConferenceCard.propTypes = {
    _id: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    topic: PropTypes.string.isRequired,
    date: PropTypes.string,
    time: PropTypes.string,
    duration: PropTypes.number,
};


export default ConferenceCard;



import React, { useState, useCallback } from 'react';

import './index.scss';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';

const SmartSlider = ({ children, slideWidth = 319, indent = 12, slidesAmount = 2 }) => {
    const [ activeItem, setActiveItem ] = useState(0);
    const [transformClass, setTransformClass] = useState(false);
    const [transformClassLeft, setTransformClassLeft] = useState(false);
    const isMobile = window.innerWidth < 659;

    const goBack = useCallback(() => {
        if (activeItem > 0) {
            setActiveItem((prev) => prev - 1);
        }
        setTransformClass(false);
        setTransformClassLeft(false);
    }, [activeItem]);

    const goForward = useCallback(() => {
        if (activeItem < children.length - slidesAmount) {
            setActiveItem((prev) => prev + 1);
        }
        setTransformClass(false);
        setTransformClassLeft(false);
    }, [activeItem, children]);

    const classes = ['untransform-ani'];

    if (transformClass) {
        classes.push('transform-ani');
    }

    if (transformClassLeft) {
        classes.push('transform-ani-left');
    }

    const onMouseLeave = () => {
        setTransformClass(false);
        setTransformClassLeft(false);
    };

    return (
        <div className="smart-slider">
            {activeItem > 0 && (
                <div
                    className="smart-slider__control smart-slider__control-left"
                    onClick={goBack}
                    onMouseOver={() => setTransformClassLeft(true)}
                    onMouseLeave={onMouseLeave}
                >
                    <div className="Icon">
                        <Icon16Chevron fill="#c4c8cc"/>
                    </div>
                </div>
            )}
            <div className="smart-slider__title">
                Invites
            </div>
            <div className="smart-slider__wrapper">
                {React.Children.map(children, (child) => (
                    <div
                        className={classes.join(' ')}
                        style={{
                            minWidth: isMobile ? `${slideWidth}px` : '245px',
                            flexBasis: isMobile ? `${slideWidth}px` : '245px',
                            transform: `translateX(${(-(isMobile ? slideWidth : 245) - indent) * activeItem}px)`,
                            transition: `all 620ms`,
                        }}
                    >
                        {React.cloneElement(child)}
                    </div>
                ))}
            </div>
            {activeItem < children.length - slidesAmount && (
                <div
                    className="smart-slider__control smart-slider__control-right"
                    onClick={goForward}
                    onMouseOver={() => setTransformClass(true)}
                    onMouseLeave={onMouseLeave}
                >
                    <div className="Icon">
                        <Icon16Chevron fill="#c4c8cc"/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SmartSlider;


import React from 'react';
import {ModalCard} from '@vkontakte/vkui';

import './index.scss'
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import {useDispatch} from "react-redux";
import {setActiveModal, setPopout} from "../../store/globals/actions";

const StreamAlert = ({id}) => {
    const dispatch = useDispatch();
    return (
        <ModalCard
            id={id}
            header="Call broadcast"
            onClise={() => dispatch(setActiveModal(null))}
        >
            <div className="ModalCard__text">
                In your profile and community<br/>
                the broadcast will be created copying<br/>
                from the current Zoom conference
            </div>
            <div className="stream-alert__close">
                <Icon16Cancel
                    fill="#818c99"
                    onClick={() => dispatch(setActiveModal(null))}
                />
            </div>

        </ModalCard>
    )
};

export default StreamAlert;

import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {store} from '@mini-core/core';
import moment from 'moment';
import * as API from '../../networking';

import {FixedLayout, Panel, PanelHeader, PanelHeaderBack, Button} from '@vkontakte/vkui';
import PlanningForm from '../../components/PlanningForm';

import './index.scss';
import {setHistoryPop} from "../../store/globals/actions";

const EditConference = ({id, conferences, stepBack}) => {
  const dispatch = useDispatch();
  const [conferencesState, conferencesActions] = store.useModel('conferences');
  const { editedConferenceId } = conferencesState;

  const editedConference = conferences.filter((conference) => conference._id === editedConferenceId);
  editedConference[0]['exact_time'] = '';
  const [editConference, setEditConference] = useState(editedConference[0]);


  const setConferenceField = (value, field, optionField = 'currentOption') => {

    if (field === 'start_time' && optionField === 'edit') {
      setEditConference((prev) => ({
        ...prev,
        [field]: value
      }));
      return;
    }

    if (field === 'start_time') {
      if (value.length > 1) {
        setEditConference((prev) => ({
          ...prev,
          [field]: value,
          [optionField]: value
        }));
        return;
      }

      setEditConference((prev) => ({
        ...prev,
        [field]: value !== '' ? moment().add(value - 1, 'day').format("YYYY-MM-DD[T]HH:mm:ss") : '',
        [optionField]: value
      }));
      return;
    }

    setEditConference((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const updateMeetingHandler = () => {
    editConference.start_time = `${editConference.start_time.slice(0, 11)}${editConference.exact_time}:00`;

    delete editConference.members;

    API.updateMeeting(editConference)
      .then(data => {
        if (data.success) {
          API.getUserMeetings()
            .then(data => {
              conferencesActions.load(data.meetings);
              stepBack();
            });
        }
      })
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={stepBack}
          />
        }
      >
        Edit
        {/*Редактирование*/}
      </PanelHeader>
      <PlanningForm
        isEdit
        startTime={editedConference[0].start_time}
        exactTime={editConference.exact_time}
        duration={editConference.duration}
        inputTopicValue={editConference.topic}
        stateSetter={setConferenceField}
      />
      <FixedLayout vertical="bottom">
        <Button
          size="xl"
          onClick={updateMeetingHandler}
          disabled={editConference.topic.trim() === '' || editConference.exact_time === '' || editConference.duration === '' || editConference.start_time === ''}
        >
          Save
          {/*Сохранить*/}
        </Button>
      </FixedLayout>
    </Panel>
  )
};

export default EditConference;

import { store } from '@mini-core/core';
const initialState = {
    profile: {},
    zoom_profile: {},
    user_token: null
};

const actions = {
    load: (profile) => {
        return {
            profile
        }
    },
    loadUserToken: (user_token) => {
        return {
            user_token
        }
    },
    loadZoomProfile: (zoom_profile) => {
        return {
            zoom_profile
        }
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'load':
            const { profile } = action;
            return {
                ...state,
                profile
            };
        case 'loadUserToken':
            const { user_token } = action;
            return {
                ...state,
                user_token
            };
        case 'loadZoomProfile':
            const { zoom_profile } = action;
            return {
                ...state,
                zoom_profile
            };
        default: return state;
    }
};

store.registerModel('user', null, initialState, actions, reducer);



import React from 'react';
import {store} from "@mini-core/core";

import ConferenceCard from '../ConferenceCard';

import './index.scss';

const MyConferences = ({conferences}) => {
  const [user, userActions] = store.useModel('user');
  const {profile} = user;

  return (
    <div className="my-conferences">
      <div className="my-conferences__title">
        {/*Мои конференции*/}
        My conferences
      </div>
      <div>
        {conferences &&
        conferences
          .map((conference) =>
            <ConferenceCard
              key={conference._id}
              profileId={profile && profile.id}
              {...conference}
            />)
        }
      </div>
    </div>
  )
};

export default MyConferences;

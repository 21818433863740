import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {store} from '@mini-core/core';
import moment from 'moment';
import localization from 'moment/locale/ru'
import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
} from '@vkontakte/vkui';
import PlanningForm from '../../components/PlanningForm';
import FooterSwitch from '../../components/FooterSwitch';

import './index.scss'
import {setHistoryPop} from "../../store/globals/actions";

const Planning = ({id, stepBack}) => {
  const dispatch = useDispatch();
  const [conferences, conferencesActions] = store.useModel('conferences');

  const [newConference, setNewConference] = useState(
    {
      topic: '',
      type: 2,
      start_time: '',
      exact_time: '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: 0,
      currentOption: '',
      members: [],
      stream: null
    }
  );

  const setConferenceField = (value, field, optionField = 'currentOption') => {

    if (field === 'start_time' && optionField === 'edit') {
      setNewConference((prev) => ({
        ...prev,
        [field]: value
      }));
      return;
    }

    if (field === 'start_time') {
      setNewConference((prev) => ({
        ...prev,
        [field]: value !== '' ? moment().add(value - 1, 'day').format("YYYY-MM-DD[T]HH:mm:ss") : '',
        [optionField]: value
      }));
      return;
    }

    setNewConference((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  useEffect(() => {
    conferencesActions.setNewConference(null);
    conferencesActions.setStreamSwitchState(false);
  }, []);


  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={stepBack}
          />
        }
      >
        Schedule
      </PanelHeader>
      <PlanningForm
        startTime={newConference.start_time}
        exactTime={newConference.exact_time}
        duration={newConference.duration}
        inputTopicValue={newConference.topic}
        stateSetter={setConferenceField}
      />
      <FooterSwitch
        setNewConference={setConferenceField}
        newConference={newConference}
      />
    </Panel>
  )
};

export default Planning;

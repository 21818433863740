import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { store } from '@mini-core/core';
import { Button, Panel } from '@vkontakte/vkui';

import './index.scss';
import zoom_logo from '../../assets/zoom_logo.png';
import FireEvent from '../../utils/FireEvent';
import * as API from '../../networking';
import {useDispatch} from "react-redux";

const Welcome = ({ id, userId }) => {
    const dispatch = useDispatch();
    const [user, userActions] = store.useModel('user');

    const oathLink = `https://zoom.us/oauth/authorize?response_type=code&client_id=Zbw2WbJZRYqDKPwRrRB7g&redirect_uri=https%3A%2F%2Fback.zoom.juice.vk-apps.com%2Fcallback_url&state=${
        process.env.NODE_ENV !== 'production' ? 160558926 : userId
    }`;

    const onLoginBtnClickHandler = () => {
        FireEvent(oathLink);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            API.getUser()
              .then((data) => {
                  if (data.found) { // !data.found
                      userActions.loadZoomProfile({...data});
                      dispatch(push('conferences'));
                  }
              });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Panel id={id} centered>
            <div className="welcome">
                <div className="container">
                    <div className="welcome__wrapper">
                        <div className="welcome__logo">
                            <img src={zoom_logo} alt="Логотип Zoom"/>
                        </div>
                        <div className="welcome__steps">
                            <div className="welcome__step">
                                <div className="welcome__step-number">
                                    1
                                </div>
                                <div className="welcome__step-text">
                                    {/*Запланируй видео-конференцию и&nbsp;пригласи друзей.*/}
                                    Schedule a video-conference and invite your friends.
                                </div>
                            </div>
                            <div className="welcome__step">
                                <div className="welcome__step-number">
                                    2
                                </div>
                                <div className="welcome__step-text">
                                    Share the link or invite participants to quickly connect to your conferences
                                    {/*Поделитесь ссылкой или приглашайте, чтобы участники быстро подключались к вашим конференциям*/}
                                </div>
                            </div>
                        </div>
                        <div className="welcome__controls">
                            <Button
                                mode="overlay_primary"
                                onClick={onLoginBtnClickHandler}
                                stretched
                                size="xl"
                            >
                                Log in
                            </Button>
                            <div
                              onClick={() => API.logoutUser()}
                            >
                                Разлогинь меня
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default Welcome;

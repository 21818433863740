import {store} from '@mini-core/core';

const initialState = {
  conferences: [],
  invites: [],
  newConference: null,
  streamSwitchState: false,
  isPlanned: false,
  activeModal: null,
};

const actions = {
  load: (conferences) => {
    return {
      conferences
    }
  },
  loadInvites: (invites) => {
    return {
      invites
    }
  },
  add: (newConference) => {
    return {
      newConference
    }
  },
  delete: (confId) => {
    return {
      confId
    }
  },
  setNewConference: (newConference) => {
    return {
      newConference
    }
  },
  setEditedConferenceId: (editedConferenceId) => {
    return {
      editedConferenceId
    }
  },
  setStreamSwitchState: (streamSwitchState) => {
    return {
      streamSwitchState
    }
  },
  setActiveModal: (activeModal) => {
    return {
      activeModal
    }
  },
  toPlan: (isPlanned) => {
    return {
      isPlanned
    }
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'load':
      const {conferences} = action;
      return {
        ...state,
        conferences
      };
    case 'loadInvites':
      const {invites} = action;
      return {
        ...state,
        invites
      };
    case 'add':
      const {newConference} = action;
      return {
        ...state,
        conferences: [...state.conferences, newConference]
      };
    case 'delete':
      const {confId} = action;
      return {
        ...state,
        conferences: state.conferences.filter((conference) => conference._id !== confId)
      };
    case 'setNewConference': {
      const {newConference} = action;
      return {
        ...state,
        newConference
      };
    }
    case 'setEditedConferenceId':
      const {editedConferenceId} = action;
      return {
        ...state,
        editedConferenceId
      };
    case 'setStreamSwitchState':
      const {streamSwitchState} = action;
      return {
        ...state,
        streamSwitchState
      };
    case 'setActiveModal':
      const {activeModal} = action;
      return {
        ...state,
        activeModal
      };
    case 'toPlan':
      const {isPlanned} = action;
      return {
        ...state,
        isPlanned
      };
    default:
      return state;
  }
};

store.registerModel('conferences', null, initialState, actions, reducer);

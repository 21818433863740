import React, {useState} from 'react';
import {push} from 'react-router-redux';
import {store} from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';
import * as API from '../../networking/';

import {Button, Cell, FixedLayout, Separator, Switch, Link, Alert} from '@vkontakte/vkui';

import './index.scss';
import {setActiveModal, setPopout} from "../../store/globals/actions";
import {useDispatch} from "react-redux";

const FooterSwitch = ({hideBtn = false, setPopup = false, updateStreamStatus = null, setNewConference, newConference}) => {
  const dispatch = useDispatch();
  const [conferences, conferencesActions] = store.useModel('conferences');
  const [userState, userActions] = store.useModel('user');
  const {streamSwitchState} = conferences;
  const {profile, zoom_profile, user_token} = userState;


  const onAddBtnClickHandler = () => {
    setNewConference(`${newConference.start_time.slice(0, 11)}${newConference.exact_time}:00`, 'start_time', 'edit');


    if (window.innerWidth < 660) {
      API.createPlanningMeeting(
        {
          topic: newConference.topic,
          type: newConference.type,
          start_time: `${newConference.start_time.slice(0, 11)}${newConference.exact_time}:00`,
          timezone: newConference.timezone,
          duration: newConference.duration,
          stream: newConference.stream
        })
        .then((newConference) => {
          conferencesActions.add(newConference.meeting);
          bridge.send("VKWebAppShare", {"link": `https://vk.com/app7453536#invite${newConference.meeting._id}${profile.id}`})
            .then(data => {
              const membersId = data.users.map(user => user.id);
              API.sendInvites(newConference.meeting._id, membersId)
                .then(response => {
                  if (response.success) {
                    API.getUserMeetings().then((data) => conferencesActions.load(data.meetings));
                    API.getUserInvites().then(data => data && conferencesActions.loadInvites(data.meetings));
                    setPopup && setPopup(false);
                    dispatch(push('conferences'));
                    conferencesActions.toPlan(true);
                  }
                });
            })
            .catch(() => {
              API.deleteMeeting(newConference.meeting._id);
              dispatch(setPopout(
                <Alert
                  actions={[{
                    title: 'Cancel',
                    autoclose: true,
                    mode: 'cancel'
                  }]}
                  onClose={() => dispatch(setPopout(null))}
                >
                  <h2>List of participants is empty</h2>
                  <p>Invite your colleagues to the conference</p>
                </Alert>)
              );
            });
        });
    } else {
      API.createPlanningMeeting(
        {
          topic: newConference.topic,
          type: newConference.type,
          start_time: `${newConference.start_time.slice(0, 11)}${newConference.exact_time}:00`,
          timezone: newConference.timezone,
          duration: newConference.duration,
          stream: newConference.stream
        }).then((newConference) => {
        conferencesActions.add(newConference.meeting);
        conferencesActions.setNewConference(newConference.meeting);
        dispatch(push('done'));
      })
        .catch(() => {
          dispatch(setPopout(
            <Alert
              actions={[{
                title: 'Cancel',
                autoclose: true,
                mode: 'cancel'
              }]}
              onClose={() => setPopout(null)}
            >
              <h2>Something goes wrong</h2>
              <p>Try again later</p>
            </Alert>)
          );
        });
    }
  };

  const onStreamCheckboxChangeHandler = (checked) => {
    if (checked) {
      bridge.send("VKWebAppCallAPIMethod",
        {
          "method": "video.startStreaming", "request_id": "32test32",
          "params": {
            "name": "Zoom meeting",
            "description": "Zoom meeting",
            "v": "5.107",
            "access_token": user_token
          }
        })
        .then((result) => {
          setNewConference(result.response.stream, 'stream');
          conferencesActions.setStreamSwitchState(checked);
        })
        .catch((e) => {
          dispatch(setPopout(
            <Alert
              actions={[{
                title: 'Cancel',
                autoclose: true,
                mode: 'cancel'
              }]}
              onClose={() => dispatch(setPopout(null))}
            >
              <h2>Ops...</h2>
              <p>Something goes wrong</p>
            </Alert>
          ));
        });
      return;
    }
    bridge.send("VKWebAppCallAPIMethod",
      {
        "method": "video.stopStreaming",
        "request_id": "32test32",
        "params": {"v": "5.107", "access_token": user_token}
      })
      .then((result) => {
        setNewConference(null, 'stream');
        conferencesActions.setStreamSwitchState(checked);
      }).catch((e) => {
      dispatch(setPopout(
        <Alert
          actions={[{
            title: 'Закрыть',
            autoclose: true,
            mode: 'cancel'
          }]}
          onClose={() => setPopout(null)}
        >
          <h2>Something goes wrong</h2>
          <p>Try again later</p>
        </Alert>)
      );
    });
  };

  return (
    <div className="footer-switch">
      <FixedLayout vertical="bottom">
        <Separator/>
        <Cell
          asideContent={
            <Switch
              checked={streamSwitchState}
              disabled={!zoom_profile.canStream}
              onChange={(e) => {
                updateStreamStatus ? updateStreamStatus(e.target.checked) : onStreamCheckboxChangeHandler(e.target.checked)
              }
              }
            />
          }
          description={
            <div>
              <Link
                onClick={() => dispatch(setActiveModal('stream-alert'))}
              >
                Details
              </Link>
              <div className="footer-switch__text">
                A duplicate public live broadcast will be created in your profile or community.
                video from the current Zoom conference
              </div>
            </div>
          }
        >
          Automatic broadcasting <br/>
          phoning VK
        </Cell>
        {!hideBtn && <div className="planning__wrapper">
          <Button
            size="xl"
            disabled={
              newConference.topic.trim().length === 0 ||
              !newConference.start_time ||
              !newConference.exact_time
            }
            onClick={onAddBtnClickHandler}
          >
            Schedule
            {/*Запланировать*/}
          </Button>
        </div>}
      </FixedLayout>
      {alert}
    </div>
  )
};

export default FooterSwitch;

import React from 'react';
import {store, utils} from '@mini-core/core';
import * as API from '../../networking';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Button } from '@vkontakte/vkui';
import CustomStack from '../CustomStack';

import Icon20CalendarOutline from '@vkontakte/icons/dist/20/calendar_outline';
import Icon20RecentOutline from '@vkontakte/icons/dist/20/recent_outline';

import './index.scss';

const InvitationCard = ({ _id, topic, start_time, duration, members}) => {
    const [conferences, conferencesActions] = store.useModel('conferences');

    const acceptHandling = () => {
        API.updateInvite(_id, true)
            .then(data => {
                if (data.success) {
                    API.getUserMeetings()
                        .then(data => conferencesActions.load(data.meetings));
                    API.getUserInvites()
                        .then(data => conferencesActions.loadInvites(data.meetings));
                }
            })
    };

    const declineHandling = () => {
        API.updateInvite(_id, false)
            .then(data => {
                if (data.success) {
                    API.getUserInvites()
                        .then(data => conferencesActions.loadInvites(data.meetings));
                }
            })
    };

    return (
        <Card mode="shadow">
            <div className="conference-card invitation-card">
                <div className="conference-card__title">
                    {topic}
                </div>
                {start_time &&
                    <div className="conference-card__date">
                        <Icon20CalendarOutline fill="#6d7885"/>
                        {moment(start_time).format("DD MMMM")}, &nbsp;
                        {moment(start_time).format("HH:mm")}
                    </div>
                }
                {duration && duration !== 0 ?
                    <div className="conference-card__duration">
                        <Icon20RecentOutline fill="#6d7885"/>
                        {duration === 100 && 'more than an hour'}
                        {duration === 60 && '1 hour'}
                        {duration < 60 && `${duration} minutes`}
                    </div> : null
                }
                <div className="conference-card__wrapper">
                    <div className="conference-card__participants">
                        <CustomStack members={members}/>
                    </div>
                    <div className="invitation-card__controls">
                        <Button
                            size="l"
                            mode="primary"
                            onClick={acceptHandling}
                        >
                            Accept
                        </Button>
                        <Button
                            size="l"
                            mode="secondary"
                            onClick={declineHandling}
                        >
                            Decline
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    )
};

InvitationCard.propTypes = {
    topic: PropTypes.string.isRequired,
    date: PropTypes.string,
    duration: PropTypes.number,
    /*participants: PropTypes.array.isRequired*/
};

export default InvitationCard;



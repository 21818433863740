import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import {createHashHistory} from "history";
import {createStore, applyMiddleware} from "redux";
import {Provider} from 'react-redux';
import {Router, Route} from 'react-router-dom';
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from './store';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import bridge from '@vkontakte/vk-bridge';
import {utils, network} from '@mini-core/core';

import App from './containers';
import '@vkontakte/vkui/dist/vkui.css';
import './store/conferences';
import './store/user';

window.vk_settings = utils.parseParams(window.location.search);
bridge.subscribe(utils.schemeChanger);

bridge.send('VKWebAppInit');

const history = createHashHistory({
  hashType: 'noslash',
});

const logger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== 'production') console.log('dispatching', action);
  return next(action);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history), logger)),
);

const urls = [
  'empty',
  'conferences',
  'welcome',
  'edit-conference',
  'planning',
  'planning-desc',
  'done',
  window.location.hash
];

const pathRegex = `/:pageId(${urls.join('|')}|)?`;

network.init('https://back.zoom.juice.vk-apps.com/', 'https://back.zoom.juice.vk-apps.com/');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route
        path={pathRegex}
        component={props =>
          <App history={history}
               pageId={props.match.params.pageId !== undefined &&
               props.match.params.pageId.length === 0 &&
               !props.match.params.pageId.includes('invite') ?
                 'empty' :
                 props.match.params.pageId}/>
        }
      />
    </Router>
  </Provider>,
  document.getElementById('root'));

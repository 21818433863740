import * as types from './actionTypes';

export const setActiveModal = (activeModal) => (
  {
    type: types.SET_ACTIVE_MODAL,
    activeModal
  }
);

export const setPopout = (activePopout) => (
  {
    type: types.SET_POPOUT,
    activePopout
  }
);

export const clearHistory = (viewPanel) => {
  return {
    type: types.CLEAR_HISTORY,
    viewPanel
  }
};

export const setHistoryPush = (nextPanel) => {
  return {
    type: types.HISTORY_PUSH,
    nextPanel
  }
};


export const setHistoryPop = () => {
  return {
    type: types.HISTORY_POP
  }
};



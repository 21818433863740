import * as types from './actionTypes';
import bridge from '@vkontakte/vk-bridge';

const initialState = {
  activeModal: null,
  activePopout: null,
  history: []
};

const globals = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_MODAL:
      const { activeModal } = action;
      return {
        ...state,
        activeModal
      };
    case types.SET_POPOUT:
      const { activePopout } = action;
      return {
        ...state,
        activePopout
      };
    case types.CLEAR_HISTORY:
      const { viewPanel } = action;
      return {
        ...state,
        history: [viewPanel]
      };
    case types.HISTORY_PUSH: {
      const shallowCopy = [...state.history];
      const { nextPanel } = action;
      shallowCopy.push(nextPanel);

      bridge.send('VKWebAppEnableSwipeBack');

      return {
        ...state,
        history: [...shallowCopy]
      };
    }
    case types.HISTORY_POP: {
      const shallowCopy = [...state.history];
      shallowCopy.pop();
      const activePanel = shallowCopy[shallowCopy.length - 1];

      if (activePanel === 'conferences') {
        bridge.send('VKWebAppDisableSwipeBack');
      }

      return {
        ...state,
        history: [...shallowCopy]
      }
    }
    default: return state
  }
};

export default globals;

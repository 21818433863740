import React from 'react';
import {ActionSheet, ActionSheetItem, platform, IOS} from '@vkontakte/vkui';
import Icon28EditOutline from '@vkontakte/icons/dist/28/edit_outline';
import Icon28DeleteOutline from '@vkontakte/icons/dist/28/delete_outline';
import {useDispatch} from "react-redux";
import {setPopout} from "../../store/globals/actions";

import './index.scss';

const PopoutEdit = ({editHandler, deleteHandler}) => {
  const dispatch = useDispatch();

    return (
        <ActionSheet onClose={() => dispatch(setPopout(null))}>
            <ActionSheetItem
                before={<Icon28EditOutline/>}
                autoclose
                onClick={editHandler}
            >
                Edit
            </ActionSheetItem>
            <ActionSheetItem
                before={<Icon28DeleteOutline/>}
                autoclose
                mode="destructive"
                onClick={deleteHandler}
            >
                Delete
            </ActionSheetItem>
            {platform() === IOS && <ActionSheetItem autoclose mode="cancel">Cancel</ActionSheetItem>}
        </ActionSheet>
    )
};

export default PopoutEdit;

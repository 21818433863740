import React from 'react';
import { utils } from '@mini-core/core';

import CustomStackItem from './CustomStackItem';

import './index.scss';

const CustomStack = ({members}) => {

  return (
    <div className="UsersStack UsersStack--size-s UsersStack--l-horizontal">
      <div className="UsersStack__photos CustomStack">
        {members.slice(0, members.length > 3 ? 3 : members.length).map((member) => <CustomStackItem member={member}/>)}
      </div>
      <div
        className="UsersStack__text"
        style={
          {
            marginLeft: members.length === 0 && 0
          }
        }
      >
        {members && members.length !== 0 && members.length}&nbsp;
        {members && members.length && members.length === 1 &&
          `member`
        }
        {members && members.length && members.length > 1 &&
          `members`
        }
      </div>
    </div>
  )
};

export default CustomStack;
